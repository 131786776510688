/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { graphql } from 'gatsby';
import { mq, colors, fontSizes } from '../theme';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import { Heading2, Paragraph, Heading3 } from '../components/Heading/Text';
import Link from '../components/Link';
import { slugify } from '../helpers/slugify.js';

const JobPage = ({ data }) => {
  const job = data.allJobsJson.nodes[0];

  return (
    <Layout>
      <SEO title={`L'entreprise partenaire ${job.name}`} />
      <Container
        background={colors.neutralLight30}
        css={{ alignItems: 'center' }}
      >
        <div
          css={mq({
            position: 'relative',
            backgroundColor: colors.neutralWhite,
            display: 'flex',
            alignItems: 'center',
            width: 128,
            height: 128,
            marginRight: [50, 0],
            marginBottom: [0, 30]
          })}
        >
          <Image
            alt={job.name}
            src={job.image}
            css={mq({
              width: 100,
              height: 100,
              marginLeft: ['auto', 'auto'],
              marginRight: ['auto', 'auto']
            })}
          />
        </div>
        <div>
          <Heading2 light>Entreprise {job.name}</Heading2>
          <Paragraph>
            {[
              job.ressources &&
                `${job.ressources} ressource${job.ressources > 0 ? 's' : ''}`,
              job.ouvrages &&
                `${job.ouvrages} ouvrage${job.ouvrages > 0 ? 's' : ''}`
            ]
              .filter(v => v !== null)
              .join(' et ')}
          </Paragraph>
        </div>
      </Container>
      <Container
        background={colors.neutralWhite}
        css={{ flexDirection: 'column' }}
      >
        <Paragraph>
          De nombreux partenaires et des milliers de ressources et ouvrages pour
          les <b>{job.description}</b>. Découvrez les différentes{' '}
          <b>familles de références</b> disponibles dans notre base de données
          en rapport avec votre métier en faisant une{' '}
          <Link
            decoration
            href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
            target="_blank"
          >
            demande de démo
          </Link>{' '}
          !
        </Paragraph>

        <Heading3 light>Nos partenaires</Heading3>

        <div css={{ display: 'flex', flexFlow: 'wrap' }}>
          {job.marques.map(marque => (
            <Link
              to={`/partenaires/${slugify(marque)}`}
              key={marque}
              css={{
                position: 'relative',
                backgroundColor: colors.neutralWhite,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 128,
                marginRight: 20,
                marginTop: 20,
                padding: '10px 0 10px 6px',
                border: `1px solid ${colors.neutralLight30}`,
                textAlign: 'center',
                ':hover': {
                  backgroundColor: colors.neutralLight
                }
              }}
            >
              <Image
                src={`brands/${slugify(marque)}.png`}
                css={mq({
                  width: 68,
                  height: 68,
                  marginLeft: ['auto', 'auto'],
                  marginRight: ['auto', 'auto'],
                  marginTop: 10,
                  marginBottom: 10
                })}
              />
              <Paragraph css={{ fontSize: fontSizes.medium, marginBottom: 0 }}>
                {marque}
              </Paragraph>
            </Link>
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default JobPage;

export const query = graphql`
  query ($jobId: String!) {
    allJobsJson(filter: { id: { eq: $jobId } }) {
      nodes {
        id
        marques
        description
        ressources
        ouvrages
        image
        name
      }
    }
  }
`;
